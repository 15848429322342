<template>
  <v-dialog
    v-model="dialog"
    persistent
    width="600"
  >
    <v-card :loading="isLoading">
      <v-row class="ma-0">
        <v-col class="d-flex justify-end">
          <v-toolbar-items>
            <v-btn
              color="black"
              icon
              dark
              @click="dialog = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-col>
      </v-row>
      <v-form
        ref="form"
        class="shippingCostLabel"
        v-model="valid"
        lazy-validation>
        <v-card-text>
          <v-row>
            <v-col>
              <v-text-field
                :label="$_strings.shippingCost.value"
                outlined
                dense
                v-model="form.name"
                :rules="rules.name"
                clearable
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-end">
          <v-btn
            color="white"
            @click="dialog = false"
          >
            {{$_strings.shippingCost.cancel}}
          </v-btn>
          <v-btn
            color="primary"
            @click="submit"
            :disabled="isLoading"
          >
            {{shippingId ? $_strings.common.EDIT : $_strings.shippingCost.save}}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  watch: {
    dialog(newVal) {
      if (this.form.name && !newVal) {
        this.$refs.form.reset();
      }
      if (!newVal) this.shippingId = null;
    },
  },
  data() {
    return {
      dialog: false,
      valid: false,
      isLoading: false,
      shippingId: '', // from list
      form: {
        name: '',
        isActive: '',
      },
      rules: {
        name: [
          (v) => !!v || this.$_strings.messages.validation.REQUIRED(this.$_strings.shippingCost.nameFormatLabel),
        ],
      },
    };
  },
  methods: {
    submit() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.isLoading = true;
      this.$_services.shippingCost.shippingCostAddEdit(this.form, this.shippingId)
        .then(() => {
          if (this.shippingId) {
            this.$dialog.notify.success(this.$_strings.categories.SUCCESS_EDIT_SHIPPINGCOST_MESSAGE_TEXT);
          } else {
            this.$dialog.notify.success(this.$_strings.categories.SUCCESS_CREATE_SHIPPINGCOST_MESSAGE_TEXT);
          }
          this.dialog = false;
          this.$emit('fetchShippingCost');
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  created() {
  },
};
</script>
