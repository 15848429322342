<template>
  <v-container fluid>
    <v-row
      dense
      align="center"
    >
      <v-col cols="auto">
        <v-btn
          v-if="userAccess.canCreate"
          color="primary"
          @click="$refs.dialogAddShippingCost.dialog = true"
        >
          {{$_strings.shippingCost.buttonAdd}}
        </v-btn>
      </v-col>
      <v-col cols="auto" md="6" class="ml-md-auto">
        <v-row dense justify="end">
          <v-col cols="12" sm="auto" md="4">
            <v-select
              v-model="filterStatus"
              :items="filterByStatus"
              :menu-props="{ bottom: true, offsetY: true }"
              class="caption mb-4"
              dense
              outlined
              hide-details
              @change="filterStatusActive"
            >
            </v-select>
          </v-col>
          <v-col cols="12" sm="auto" md="5">
            <v-text-field
              dense
              hide-details
              outlined
              class="caption"
              v-model="searchText"
              :placeholder="$_strings.shippingCost.searchText"
              @keyup.enter="fetching"
            >
              <template v-slot:prepend-inner>
                <v-icon @click="fetching"> mdi-magnify </v-icon>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          item-key="id"
          :loading="isLoading"
          :headers="displayedHeader"
          :items="items"
          :server-items-length="totalItems"
          :options.sync="pagination"
          :footer-props="{
            showCurrentPage: true,
            showFirstLastPage: true,
            'items-per-page-options': $_item_per_page,
            disablePagination: isLoading,
            disableItemsPerPage: isLoading
          }">
          <template v-slot:[`item.createdBy`]={item}>
            <span>{{ item.createdBy }}</span>
            <br/>
            <span>{{ dateFormat(item.createAt) }}</span>
          </template>
          <template v-slot:[`item.isActive`]={item}>
            <v-select
              class="mt-5 pa-0 caption"
              style="width: 8rem"
              outlined
              dense
              :items="itemStatus"
              item-value="value"
              item-text="key"
              v-model="item.isActive"
              @click.stop.prevent
              @change="onChangeIsActive(item, $event)"
              :disabled="!userAccess.canUpdate"
              :loading="item.isLoading"
            >
            </v-select>
          </template>
          <template v-slot:[`item.actions`]={item}>
            <v-tooltip top v-if="userAccess.canUpdate">
              <template v-slot:activator="{ on, attrs }">
                <v-btn  v-bind="attrs" v-on="on" x-small icon color="primary" @click="toggleDialogShippingCost(item)" >
                  <v-icon> mdi-pencil </v-icon>
                </v-btn>
              </template>
              <span>{{$_strings.common.EDIT}}</span>
            </v-tooltip>
          </template>
          <template v-slot:[`footer.page-text`]="props">
            <span>
              {{$_strings.shippingCost.pageName}}
              <span v-if="items.length">
                {{props.pageStart}}-{{props.pageStop}} of {{props.itemsLength}}
              </span>
            </span>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <AddShippingCost
      ref="dialogAddShippingCost"
      @fetchShippingCost="fetchShippingCost"
    />
  </v-container>
</template>

<script>
import {
  dateFormat,
  skipEmptyStringObject,
  handleSortBy,
  handlerPagination,
  defaultPagination,
} from '@/helper/commonHelpers';
import AddShippingCost from './Dialog/AddShippingCost.vue';

export default {
  name: 'shipping-cost',
  components: {
    AddShippingCost,
  },
  data() {
    return {
      isLoading: false,
      items: [],
      searchText: '',
      headers: [
        {
          text: this.$_strings.shippingCost.name,
          value: 'name',
          class: 'white--text primary text-capitalize',
        },
        {
          text: this.$_strings.shippingCost.createdBy,
          value: 'createdBy',
          class: 'white--text primary text-capitalize',
        },
        {
          text: this.$_strings.vaccine.STATUS,
          value: 'isActive',
          class: 'white--text primary text-capitalize',
        },
        {
          text: 'Aksi',
          value: 'actions',
          class: 'white--text primary text-capitalize',
          sortable: false,
        },
      ],
      pagination: this.defaultPagination(),
      totalItems: 0,
      filterStatus: this.$route.query.isActive || '',
      filterByStatus: [
        {
          text: 'Semua Status',
          value: '',
        },
        {
          text: 'Aktif',
          value: 'qistrue',
        },
        {
          text: 'Tidak Aktif',
          value: 'qisfalse',
        },
      ],
      itemStatus: [
        {
          key: 'Aktif',
          value: true,
        },
        {
          key: 'Tidak Aktif',
          value: false,
        },
      ],
    };
  },
  computed: {
    displayedHeader() {
      const { canUpdate } = this.userAccess;
      return this.headers.filter((header) => {
        if (!canUpdate && header.value === 'actions') return;
        return header;
      });
    },
  },
  watch: {
    pagination: {
      handler(newVal) {
        this.handlerPagination(this, newVal);
        if (!this.isLoading) this.fetchShippingCost();
      },
      deep: true,
    },
  },
  methods: {
    dateFormat,
    skipEmptyStringObject,
    handleSortBy,
    handlerPagination,
    defaultPagination,
    fetching() {
      if (this.pagination.page > 1) {
        this.pagination.page = 1;
        return;
      }
      this.fetchShippingCost();
    },
    toggleDialogShippingCost(item) {
      this.$refs.dialogAddShippingCost.dialog = true;
      if (item) {
        this.$refs.dialogAddShippingCost.shippingId = item.id;
        this.$refs.dialogAddShippingCost.form.name = item.name;
        this.$refs.dialogAddShippingCost.form.isActive = item.isActive;
      }
    },
    async fetchShippingCost() {
      const {
        page, itemsPerPage, sortBy, sortDesc,
      } = this.pagination;
      const filters = {
        page: page - 1,
        size: itemsPerPage,
        isActive: this.filterStatus,
        name: '',
        sort: this.handleSortBy({ sortBy, sortDesc }),
      };
      if (this.searchText) filters.name = `qLike(${this.searchText})`;
      try {
        this.isLoading = true;
        const result = await this.$_services.shippingCost.getListShippingCost(this.skipEmptyStringObject(filters));
        this.items = result.data.contents;
        this.totalItems = result.data.totalData;
      } finally {
        this.isLoading = false;
      }
    },
    filterStatusActive() {
      if (this.filterActive !== this.$route.query.isActive) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            isActive: this.filterActive,
          },
        });
      }
      this.fetching();
    },
    async onChangeIsActive(item, event) {
      const form = {
        ...item,
        isActive: event,
      };
      try {
        this.$set(item, 'isLoading', true);
        await this.$_services.shippingCost.shippingCostAddEdit(form, item.id);
        this.$dialog.notify.success(this.$_strings.shippingCost.successEditStatus);
      } catch {
        this.$nextTick(() => {
          item.isActive = !event;
        });
      } finally {
        this.$delete(item, 'isLoading');
      }
    },
  },
};
</script>
