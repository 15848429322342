var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"dense":"","align":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[(_vm.userAccess.canCreate)?_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.$refs.dialogAddShippingCost.dialog = true}}},[_vm._v(" "+_vm._s(_vm.$_strings.shippingCost.buttonAdd)+" ")]):_vm._e()],1),_c('v-col',{staticClass:"ml-md-auto",attrs:{"cols":"auto","md":"6"}},[_c('v-row',{attrs:{"dense":"","justify":"end"}},[_c('v-col',{attrs:{"cols":"12","sm":"auto","md":"4"}},[_c('v-select',{staticClass:"caption mb-4",attrs:{"items":_vm.filterByStatus,"menu-props":{ bottom: true, offsetY: true },"dense":"","outlined":"","hide-details":""},on:{"change":_vm.filterStatusActive},model:{value:(_vm.filterStatus),callback:function ($$v) {_vm.filterStatus=$$v},expression:"filterStatus"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"auto","md":"5"}},[_c('v-text-field',{staticClass:"caption",attrs:{"dense":"","hide-details":"","outlined":"","placeholder":_vm.$_strings.shippingCost.searchText},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.fetching($event)}},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('v-icon',{on:{"click":_vm.fetching}},[_vm._v(" mdi-magnify ")])]},proxy:true}]),model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}})],1)],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"item-key":"id","loading":_vm.isLoading,"headers":_vm.displayedHeader,"items":_vm.items,"server-items-length":_vm.totalItems,"options":_vm.pagination,"footer-props":{
          showCurrentPage: true,
          showFirstLastPage: true,
          'items-per-page-options': _vm.$_item_per_page,
          disablePagination: _vm.isLoading,
          disableItemsPerPage: _vm.isLoading
        }},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.createdBy",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.createdBy))]),_c('br'),_c('span',[_vm._v(_vm._s(_vm.dateFormat(item.createAt)))])]}},{key:"item.isActive",fn:function(ref){
        var item = ref.item;
return [_c('v-select',{staticClass:"mt-5 pa-0 caption",staticStyle:{"width":"8rem"},attrs:{"outlined":"","dense":"","items":_vm.itemStatus,"item-value":"value","item-text":"key","disabled":!_vm.userAccess.canUpdate,"loading":item.isLoading},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();},"change":function($event){return _vm.onChangeIsActive(item, $event)}},model:{value:(item.isActive),callback:function ($$v) {_vm.$set(item, "isActive", $$v)},expression:"item.isActive"}})]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [(_vm.userAccess.canUpdate)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"x-small":"","icon":"","color":"primary"},on:{"click":function($event){return _vm.toggleDialogShippingCost(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-pencil ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$_strings.common.EDIT))])]):_vm._e()]}},{key:"footer.page-text",fn:function(props){return [_c('span',[_vm._v(" "+_vm._s(_vm.$_strings.shippingCost.pageName)+" "),(_vm.items.length)?_c('span',[_vm._v(" "+_vm._s(props.pageStart)+"-"+_vm._s(props.pageStop)+" of "+_vm._s(props.itemsLength)+" ")]):_vm._e()])]}}],null,true)})],1)],1),_c('AddShippingCost',{ref:"dialogAddShippingCost",on:{"fetchShippingCost":_vm.fetchShippingCost}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }